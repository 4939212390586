import React from 'react';
import './App.css';
import TradingViewWidget from './TradingEngine';

function App() {
  return (
    <div className="App">
      
        <TradingViewWidget />
    </div>
  );
}

export default App;
